/**
 * 当前屏幕宽高
 * @type {{width: null, height: null}}
 */
export const screenInfo = {
    width: null,
    height: null,
}
export default {
    /**
     * 初始化屏幕宽高信息
     */
    init(_this) {
        if (_this) {
            switch (_this.$vuetify.breakpoint.name) {
                case "lg":
                case "xl":
                case "md": {
                    _this.$store.commit('setScreenInfo', {
                        width: window.innerWidth,
                        height: window.innerHeight,
                    });
                    window.onresize = () => {
                        _this.$store.commit('setScreenInfo', {
                            width: window.innerWidth,
                            height: window.innerHeight,
                        });
                    }
                }
                    return;
                case "sm":
                case "xs": {
                    _this.$store.commit('setScreenInfo', {
                        width: document.documentElement.clientWidth,
                        height: document.documentElement.clientHeight
                    });
                    return
                }
                default:
                    _this.$store.commit('setScreenInfo', {
                        width: null,
                        height: null,
                    });
            }
        }
    },
    screenInfo
}

