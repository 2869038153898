<template>
  <SysContainer>
    <v-row>
      <v-col
          sm="6"
          md="4"
          sx="6"
          xl="2">
        <v-text-field
            v-model="searchParams.title"
            :hide-details="'auto'"
            label="Outlined"
            outlined
            dense
        />
      </v-col>
      <v-col xs="6">
        <CustomizeButton
            @click="getContentList"
            color="info"
            title="搜索"
            icon="mdi-magnify"></CustomizeButton>
        <CustomizeButton
            @click="reset"
            title="重置"
            icon="mdi-reload"></CustomizeButton>
      </v-col>
    </v-row>
    <v-row>
      <v-col
          cols="12">
        <CustomizeButton
            @click="add"
            title="新建"
            icon="mdi-file-document-plus-outline"></CustomizeButton>
      </v-col>
    </v-row>
    <CustomizeCard style="padding: 10px;margin-top: 12px">
      <v-data-table
          :dark="$vuetify.theme.dark"
          :headers="headers"
          :items="list"
          :loader-height="2"
          :disable-pagination="true"
          :hide-default-footer="true"
          :disable-sort="true"
          :disable-filtering="true">

        <template v-slot:[`item.text`]="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <span v-on="on">{{ textIntercept(item.text) }}</span>
            </template>
            <span>{{ item.text }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.state`]="{ item }">
          <span v-if="item.state === 1">发布</span>
          <span v-if="item.state === 2">草稿</span>
          <span v-if="item.state === 3">隐藏</span>
        </template>
        <template v-slot:[`item.actions`]="{ item,index }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  v-bind="attrs"
                  v-on="on"
                  class="mx-1"
                  icon
                  x-small
                  @click="edit(item,index)">
                <v-icon>
                  mdi-pencil
                </v-icon>
              </v-btn>
            </template>
            <span>编辑</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  v-bind="attrs"
                  v-on="on"
                  class="mx-1"
                  icon
                  x-small
                  @click="remove(item,index)">
                <v-icon>
                  mdi-delete
                </v-icon>
              </v-btn>
            </template>
            <span>删除</span>
          </v-tooltip>
          <v-tooltip top v-if='!item.theCover'>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  v-bind="attrs"
                  v-on="on"
                  class="mx-1"
                  icon
                  x-small
                  @click="setTheCover(item,index)">
                <v-icon>
                  mdi-emoticon-neutral-outline
                </v-icon>
              </v-btn>
            </template>
            <span>设为封面</span>
          </v-tooltip>
          <v-tooltip top v-if="item.theCover">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  v-bind="attrs"
                  v-on="on"
                  class="mx-1"
                  icon
                  x-small
                  @click="setTheCover(item,index)">
                <v-icon>
                  mdi-emoticon-happy-outline
                </v-icon>
              </v-btn>
            </template>
            <span>取消封面</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  v-bind="attrs"
                  v-on="on"
                  class="mx-1"
                  icon
                  x-small
                  @click="remove(item,index)">
                <v-icon>
                  mdi-delete
                </v-icon>
              </v-btn>
            </template>
            <span>删除</span>
          </v-tooltip>
          <v-tooltip top v-if="item.state === 1">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  v-bind="attrs"
                  v-on="on"
                  class="mx-1"
                  icon
                  x-small
                  @click="hideOrUnhide(item,index)">
                <v-icon>
                  mdi-eye-outline
                </v-icon>
              </v-btn>
            </template>
            <span>隐藏</span>
          </v-tooltip>
          <v-tooltip top v-if="item.state === 3">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  v-bind="attrs"
                  v-on="on"
                  class="mx-1"
                  icon
                  x-small
                  @click="hideOrUnhide(item,index)">
                <v-icon>
                  mdi-eye-off-outline
                </v-icon>
              </v-btn>
            </template>
            <span>取消隐藏</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </CustomizeCard>
    <div style="width: 100%">
      <v-container fluid>
        <v-row
            justify="center"
            align="center">
          <v-container class="max-width">
            <v-pagination
                class="my-4"
                v-model="offset"
                :length="page.length"
                @next="nextPage"
                @previous="nextPrevious"
            ></v-pagination>
          </v-container>
        </v-row>
      </v-container>
    </div>
    <ContentManagementForm
        :form-dialog-show="contentManagementFormShow"
        :content-id="contentId"
        @closeFormDialog="closeFormDialog"></ContentManagementForm>

  </SysContainer>

</template>

<script>
import CustomizeButton from "@/components/CustomizeButton/index.vue";
import CustomizeCard from "@/components/CustomizeCard/index.vue";
import SysContainer from "@/components/SysContainer.vue";
import ContentManagementForm from "@/views/admin/contentManagement/components/ContentManagementForm.vue";
import {
  cancelTheCoverContent,
  deleteContent,
  getContentList,
  hideOrUnhide,
  setTheCoverContent
} from "@/api/admin/content";

export default {
  name: "ContentManagement",
  components: {ContentManagementForm, SysContainer, CustomizeCard, CustomizeButton},
  data() {
    return {
      contentManagementFormShow: false,
      loader: null,
      list: [],
      searchParams: {
        offset: 1,
        limit: 10
      },
      headers: [
        {text: '标题', value: 'title', align: 'center', width: "180px"},
        {text: '文本', value: 'text', align: 'center'},
        {text: 'IP', value: 'publishIp', align: 'center', width: "100px"},
        {text: '创建时间', value: 'createdTime', align: 'center', width: "180px"},
        {text: '更新时间', value: 'updatedTime', align: 'center', width: "180px"},
        {text: '状态', value: 'state', align: 'center', width: "80px"},
        {text: '操作', value: 'actions', align: 'center', width: "180px"},
      ],
      contentId: "",
      page: {
        length: 0,
        total: 0
      },
      offset: 1,

    }
  },
  watch: {
    offset(val) {
      this.searchParams.offset = val;
      this.getContentList();
    },
  },
  created() {
    this.getContentList();
  },
  methods: {
    textIntercept(text) {
      if (!text) {
        return '';
      }
      return (text.length > 10) ? (text.substr(0, 20) + '...') : text
    },
    add() {
      this.contentId = "";
      this.contentManagementFormShow = true;
    },
    async getContentList() {
      const res = await getContentList(this.searchParams);
      if (res.code === 0) {
        this.list = res.data.rows;
        this.page.total = res.data.total;
        this.page.length = Math.ceil(this.page.total / this.searchParams.limit);
        this.$forceUpdate();
      }
    },
    closeFormDialog() {
      this.contentManagementFormShow = false;
      this.getContentList();
    },
    search() {
      this.contentManagementFormShow = true;
    },
    reset() {
      this.searchParams = {
        offset: 1,
        limit: 10,
        title: '',
      };
      this.getContentList();
    },
    remove(item) {
      this.$confirm.dialog("是否确认删除")
          .then(async () => {
            const res = await deleteContent(item.id);
            if (res.code === 0) {
              this.$message.success('删除成功');
            } else {
              this.$message.closeButtonContent('close').error('删除失败');
            }
            await this.getContentList();
          })

    },
    edit(item) {
      this.contentId = item.id;
      this.contentManagementFormShow = true;
    },
    async setTheCover(item) {
      const res = item.theCover
          ? await cancelTheCoverContent({id: item.id}) : await setTheCoverContent({id: item.id})
      if (res.code === 0) {
        this.$message.success("操作成功");
        await this.getContentList();
      } else {
        this.$message.closeButtonContent('close').error(res.msg);
      }
    },
    hideOrUnhide(item, index) {
      const state = item.state === 1 ? 3 : 1;
      hideOrUnhide({
        id: item.id,
        state: state
      }).then(res => {
        if (res.code === 0) {
          this.$message.success("更新状态成功");
          this.list[index].state = state;
        } else {
          this.$message.closeButtonContent('close').error(res.msg);
        }
      })
    },
    nextPage() {
      if ((this.offset + 1) > this.page.length) {
        return
      }
      this.offset++;
    },
    nextPrevious() {
      if ((this.offset - 1) < 1) {
        return
      }
      this.offset--;
    },

  }

}
</script>

<style scoped>
.buttons-group {
  margin-bottom: 12px;
}
</style>
