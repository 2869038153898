<template>
  <div class="dialog-mask-layer" v-if="copyShow">
    <v-progress-circular
        :size="50"
        color="primary"
        indeterminate
    ></v-progress-circular>
  </div>
</template>
<script>
export default {
  name: "CustomizeLoading",
  props: {
    show: {
      type: Boolean,
      default: () => {
        return false;
      }
    }
  },
  watch: {
    show(newVal) {
      this.copyShow = newVal;
    },
  },
  data() {
    return {
      copyShow: false
    }
  },
  mounted() {
    this.copyShow = this.show;
  }
}
</script>
<style scoped>
.dialog-mask-layer {
  z-index: 9999;
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity .3s;
  background-color: rgba(0, 0, 0, 0.8);
}
</style>
