<template>
<div>
  首页
</div>
</template>

<script>
export default {
  name: "AdminHome",

}
</script>

<style scoped>

</style>