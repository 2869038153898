<template>
  <v-app id="inspire">
    <router-view></router-view>
  </v-app>
</template>

<script>

export default {
  name: 'HomeIndex',
}
</script>
<style scoped>

</style>
