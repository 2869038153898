var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',_vm._g(_vm._b({attrs:{"width":_vm.$vuetify.breakpoint.xsOnly ? '100%' : _vm.copyWidth,"persistent":_vm.copyPersistent,"no-click-animation":true,"overlay-opacity":0.7,"overlay-color":'#7F7F7F',"fullscreen":_vm.copyWidth ? false : _vm.copyFullscreen},model:{value:(_vm.copyShowDialog),callback:function ($$v) {_vm.copyShowDialog=$$v},expression:"copyShowDialog"}},'v-dialog',_vm.$attrs,false),_vm.$listeners),[_c('CustomizeLoading',{style:({
      width: _vm.$vuetify.breakpoint.xsOnly ? '100%' : _vm.copyWidth,
      height: this.customizeCardStyle.height + 'px' }),attrs:{"show":_vm.copyLoading}}),_c('CustomizeCard',{ref:"contentCard",staticStyle:{"width":"100%"},style:({height: _vm.customizeCardStyle.height +'px'}),attrs:{"fill-height":true}},[(_vm.copyShowToolbar)?_c('v-toolbar',{ref:"dialogToolbar",style:({height: _vm.toolbar.height + 'px', backgroundColor:_vm.toolbarColor}),attrs:{"dark":"","dense":""}},[_vm._t("toolbarLeftButtons"),_c('v-spacer'),_vm._t("toolbarRightButtons")],2):_vm._e(),_c('div',{staticStyle:{"overflow":"auto"},style:({height:_vm.contentDiv.height + 'px'})},[_vm._t("content")],2),(_vm.copyCardActionsShow)?_c('v-card-actions',{style:({
            height: _vm.buttonCardStyle.height + 'px',
            boxShadow: '0 0px 4px -1px rgba(0,0,0,.2), 0 0px 5px 0 rgba(0,0,0,.14), 0 0px 10px 0 rgba(0,0,0,.12)',
            position: 'absolute',
            width: '100%'
        })},[(!_vm.copyCenterButtons)?_vm._t("cardActionsLeftButtons"):_vm._e(),(!_vm.copyCenterButtons)?_c('v-spacer'):_vm._e(),(!_vm.copyCenterButtons)?_vm._t("cardActionsRightButtons"):_vm._e(),(_vm.copyCenterButtons)?_vm._t("cardActionsCenterButtons"):_vm._e()],2):_vm._e()],1),_vm._t("childDialog")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }