function debounce(func, delay) {
    let timerId;

    return function () {
        const context = this;
        const args = arguments;

        clearTimeout(timerId);
        timerId = setTimeout(() => {
            func.apply(context, args);
        }, delay);
    };
}

import imageUtil from "@/util/image"

const staticSource = window.location.origin + process.env.VUE_APP_BASE_API
export default {
    imageUtil,
    debounce,
    staticSource
}

