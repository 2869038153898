<template>
  <v-hover v-slot="{ hover }">
    <v-card
        :style="vCardStyle"
        :min-height="fillHeight ? '' : minHeight"
        :fill-height="fillHeight"
        :elevation="(hover && showHover) ? 16 : elevation">
      <slot>
      </slot>
    </v-card>
  </v-hover>
</template>

<script>
export default {
  name: "CustomizeCard",
  data() {
    return {}
  },
  props: {
    showHover: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    fillHeight: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    minHeight: {
      type: String,
      default: () => {
        return ''
      }
    },
    elevation: {
      type: Number,
      default: () => {
        return 2
      }
    }
  },
  mounted() {
  },
  computed: {
    vCardStyle() {
      return this.$vuetify.theme.dark ? {backgroundColor: '#272727'} : {}
    }
  }
}
</script>

<style scoped>

</style>
