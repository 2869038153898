import Vuex from 'vuex';
import Vue from "vue";
import {screenInfo} from "./module/onresize.js"
import {buttonLoading} from "@/store/module/buttonLoading";
import {navigationState} from "@/store/module/navigationState";

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        screenInfo,
        buttonLoading,
        navigationState
    },

    mutations: {
        setScreenInfo(state, payload) {
            state.screenInfo = payload
        },
        setButtonLoading(state, payload) {
            state.buttonLoading = payload;
        },
        setNavigationState(state, payload) {
            state.navigationState = payload;
        },
    }


})
export default store

