<template>
  <v-app>
    <v-app-bar app ref="header" elevation="0" width="100%"
               flat
               :style="{background:$vuetify.theme.dark ? '#353739' : '#FFFFFF'}">
      <head-index ref="headIndex" @search="search" @openSettingDrawer="openSettingDrawer"></head-index>
    </v-app-bar>
    <v-main :style="{minHeight: mainStyle.minHeight, background:$vuetify.theme.dark ? '#353739' : '#FFFFFF'}">
      <v-container
          ref="scrollContainer"
          fluid
          :dark="$vuetify.theme.dark"
          :style="{backgroundColor: $vuetify.theme.dark ? '#353739' : '#FFFFFF'}"
          style="height: 100%;padding: 0">
        <content-page
            ref="contentPage"
            v-show="listSize > 0"
            @changeTag="changeTag"
            @listLoadEnd="listLoadEnd"/>
        <v-row v-show="listSize <= 0" style="height: 100%" no-gutters align-content="center" align="center"
               justify="center">
          <v-col cols="12" align-self="center" justify="center" style="text-align: center;"
                 class="text-h6 text-lg-h5 text-md-h5 ">
            暂无数据
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <v-footer
        ref="footer"
        color="lighten-1"
        padlessx
    >
      <div style="width: 100%">
        <v-divider
            :style="{border: $vuetify.theme.dark ? '1px solid #222228' : '1px solid #373633'}"></v-divider>
        <v-row
            justify="center"
            no-gutters>
          <v-col
              align-self="center"
              :style="{backgroundColor: $vuetify.theme.dark ? '#353739' : '#FFFFFF',}"
              class="pt-4 pb-4 text-center #1765BC--text"
              cols="12"
              lg="12"
          >
            <strong>{{ new Date().getFullYear() }}</strong>
          </v-col>
        </v-row>
      </div>

    </v-footer>
    <setting-index
        :setting-drawer-show="settingDrawerShow"
        @closeSettingDrawer="settingDrawerShow=false"></setting-index>

  </v-app>
</template>

<script>

import {colConf} from "@/views/home/js/layout";
import ContentPage from "@/views/home/content/index.vue";
import HeadIndex from "@/views/home/head/index.vue";
import SettingIndex from "@/views/home/components/setting/index.vue";

export default {
  name: 'HomeIndex',
  components: {SettingIndex, HeadIndex, ContentPage},
  data() {
    return {
      mainStyle: {
        minHeight: "",
      },
      listSize: 1,
      settingDrawerShow: false,
    }
  },
  async mounted() {
    this.mainAreaStyle(window.innerHeight)
    window.onresize = () => this.mainAreaStyle(window.innerHeight);
  },
  computed: {
    colConf() {
      return colConf
    }
  },
  watch: {},
  methods: {
    search(tag) {
      this.listSize = 2;
      this.$refs.contentPage.search(tag)
    },
    mainAreaStyle(maxHeight) {
      this.mainStyle.minHeight =
          (maxHeight
              - this.$refs.header.$el.offsetHeight
              - this.$refs.footer.$el.offsetHeight)
          + 'px';
      this.$forceUpdate()
    },
    listLoadEnd(listSize) {
      this.$vuetify.breakpoint.update()
      this.$refs.headIndex.changeShowSkeleton(this.$vuetify.breakpoint.height);
      this.listSize = listSize;
      this.mainAreaStyle();
    },
    changeTag(tag) {
      this.$refs.headIndex.changeTagByContent(tag);
    },
    openSettingDrawer() {
      this.settingDrawerShow = true;
    },
  }
}
</script>
<style scoped>
.container {
  padding: 0;
}

/deep/ .v-footer {
  padding: 0;
}

/deep/ .v-toolbar__content {
  display: flex;
  justify-content: center;
  padding: 0;
}
</style>
