export function getFileFromUrl(url, fileName, accept) {
    return new Promise((resolve, reject) => {
        url = window.location.origin + url;
        let type = 'image/' + accept.toLocaleLowerCase()
        let blob = null;
        let xhr = new XMLHttpRequest();
        xhr.open("GET", url);
        xhr.setRequestHeader('Accept', type);
        xhr.responseType = "blob";
        // 加载时处理
        xhr.onload = () => {
            // 获取返回结果
            blob = xhr.response;
            // 返回结果
            resolve(new File([blob], fileName, {type: type}));
        };
        xhr.onerror = (e) => {
            reject(e);
        };
        // 发送
        xhr.send();
    });
}

export function fileSizeConvert(num) {
    if (!num) {
        return 0;
    }

    return Math.ceil(num / 1024 / 1024);
}

export function formatFileSize(size) {
    if (size === 0) return '0 B';
    var k = 1024,
        // sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        sizes = ['B', 'KB', 'M', 'G'],// 到GB即可，目前不需要这么大的单位，甚至到M
        i = Math.floor(Math.log(size) / Math.log(k));

    return (size / Math.pow(k, i)).toPrecision(3) + '' + sizes[i];
}

export default class imageUtil {
}
