import service from "@/conf/service";

export function getResourceDescList(query) {
    return service(
        {
            url: '/api/resource/list',
            method: 'get',
            params: query,
        });
}

export function deleteResourceDesc(id) {
    return service(
        {
            url: '/api/resource/delete/' + id,
            method: "post",
        }
    )
}

export function getPublicResourceDescList(query) {
    return service(
        {
            url: '/public/resource/list',
            method: 'get',
            params: query,
        });
}

export function getPublicMarkdownResourceDescList(query) {
    return service(
        {
            url: '/public/resource/markdown/list',
            method: 'get',
            params: query,
        });
}

export function getHtmlById(id) {
    return service(
        {
            url: '/public/markdown/' + id,
            method: 'get',
        });
}
