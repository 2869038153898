<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>

export default {

  data() {
    return {}
  },
  mounted() {
    console.log(this.$vuetify.breakpoint.name)
    this.onresize.init(this);

  },
  computed: {},
  methods: {}
}
</script>
<style lang="sass" scoped>
.v-card.on-hover.theme--dark
  background-color: rgba(#FFF, 0.8)

  > .v-card__text
    color: #000
</style>
