import router from '@/conf/router'
// import NProgress from 'nprogress'
// import 'nprogress/nprogress.css'
import {getToken} from '@/util/token'

// NProgress.configure({showSpinner: false})

const whiteList = ['/login', '/401', '/404', "/", "/robots.txt"]

router.beforeEach((to, from, next) => {
    // NProgress.start()
    if (getToken()) {
        /* has token*/
        if (to.path === '/login') {
            next({path: '/admin/content/management'})
            // NProgress.done()
        } else {
            next()
        }
    } else {
        // 没有token
        if (whiteList.indexOf(to.path) !== -1) {
            // 在免登录白名单，直接进入
            next()
        } else {
            next(`/login?redirect=${to.fullPath}`) // 否则全部重定向到登录页
            // NProgress.done()
        }
    }
})

router.afterEach(() => {
    // NProgress.done()
})
