<template>
  <v-btn
      style="margin-right: 12px"
      :loading="$store.state.buttonLoading"
      :disabled="$store.state.buttonLoading"
      v-on="$listeners"
      :color="color"
  >
    {{ title }}
    <v-icon
        right
        :dark="$vuetify.theme.dark">
      {{ icon }}
    </v-icon>
  </v-btn>
</template>

<script>
export default {
  name: "CustomizeButton",
  props: {
    title: {
      type: String,
      default: () => {
        return ''
      }
    },
    icon: {
      type: String,
      default: () => {
        return ''
      },
    },
    color: {
      type: String,
      default: () => {
        return ''
      },
    }
  },
  methods: {},
}
</script>

<style scoped>

</style>