<template>
  <div ref="livePhotos" :style="copyLivePhotoStyle"/>
</template>

<script>
import * as LivePhotosKit from 'livephotoskit';

export default {
  name: 'LivePhotos',
  props: {
    photoSrc: {
      type: String,
      required: true
    },
    videoSrc: {
      type: String,
      required: true
    },
    livePhotoStyle: {
      type: Object,
      default: null
    },

  },
  data() {
    return {
      copyLivePhotoStyle: {},
      player: null,
    }
  },
  watch: {
    livePhotoStyle: {
      handler(data) {
        this.copyLivePhotoStyle = data;
        this.$nextTick(() => this.player.updateSize(true));
      },
      deep: true
    },
  },
  mounted() {
    this.copyLivePhotoStyle = this.livePhotoStyle;
    this.$nextTick(() => this.initializeLivePhoto());
  },
  methods: {
    initializeLivePhoto() {
      if (LivePhotosKit) {
        const container = this.$refs.livePhotos;
        this.player = new LivePhotosKit.augmentElementAsPlayer(container, {
          videoSrc: this.videoSrc,
          photoSrc: this.photoSrc,
          proactivelyLoadsVideo: this.settings.getSettingsJson().livePhotos.autoLoad === '1',
        });
      } else {
        console.error('LivePhotosKit is not loaded');
      }
    },
  }
}
</script>

<style scoped>

</style>
