import service from "@/conf/service";

export function login(data) {
    return service(
        {
            url: '/login',
            method: "post",
            data: data
        }
    )
}
