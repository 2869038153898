const key = 'token'
export function getToken() {
    return localStorage.getItem(key)
}

export function setToken(token) {
    return localStorage.setItem(key, token)
}

export function removeToken() {
    return localStorage.removeItem(key)
}
const needTokenUrl = ['/api'];

export function needToken(url) {
    if (typeof url == "undefined" || url === null || url === "") return false
    return needTokenUrl.indexOf(('/' + url.split('/')[1])) > -1;
}
