<template>
  <v-navigation-drawer
      v-model="copyDrawer"
      absolute
      bottom
      width="240"
      temporary>
    <v-list>
      <v-list-item class="px-2">
        <v-list-item-avatar style="text-align: center">
          <v-img src="https://randomuser.me/api/portraits/women/85.jpg"></v-img>
        </v-list-item-avatar>
      </v-list-item>
    </v-list>
    <v-divider></v-divider>
    <v-list
        nav
        dense>
      <v-list-item link>
        <v-list-item-icon>
          <v-icon>mdi-folder</v-icon>
        </v-list-item-icon>
        <v-list-item-title>My Files2</v-list-item-title>
      </v-list-item>
      <v-list-item link>
        <v-list-item-icon>
          <v-icon>mdi-account-multiple</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Shared with me</v-list-item-title>
      </v-list-item>
      <v-list-item link>
        <v-list-item-icon>
          <v-icon>mdi-star</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Starred</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>

</template>

<script>
export default {
  name: "MobileNavigation",
  props: {
    drawer: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  },
  data() {
    return {
      copyDrawer: null,
    }
  },
  watch: {
    drawer(newVal) {
      this.copyDrawer = newVal;
    },
  },
  mounted() {
    this.copyDrawer = this.drawer;
  }
}
</script>

<style scoped>

</style>