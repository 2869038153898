<template>
  <v-container
      fluid
      ma-0>
    <slot></slot>
  </v-container>
</template>

<script>
export default {
  name: "SysContainer",
  watch: {},
  mounted() {
  },
  data() {
    return {}
  },
}
</script>

<style scoped>

</style>