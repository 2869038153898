import Vue from 'vue';
import Confirm from '@/components/Confirm/index.vue'
import Vuetify from '@/plugins/vuetify';

const vms = {
    create(comp, obj) {
        let vm = new comp()
        vm.$vuetify = Vuetify.framework;
        vm = vm.$mount();
        if (obj)
            for (let o in obj) {
                vm[o] = obj[o];
            }
        document.body.appendChild(vm.$el);
        return vm;
    }
}

const MyDialog = Vue.extend(Confirm);

const confirm = {
    dialog(message, title) {
        const vm = vms.create(MyDialog);
        let promise = new Promise((resolve, reject) => {
            vm.$on('ok', () => resolve(true));
            vm.$on('cancel', () => reject(false))
        });
        vm.title = title;
        vm.message = message;
        vm.dialog = true;
        return promise;
    },
}


export default confirm;
