<template>
  <customize-dialog
      ref="contentDialog"
      :transition="'dialog-bottom-transition'"
      :show-dialog="copyContentDialogShow"
      :loading="loading"
      :fullscreen="true"
      :persistent="true"
      :card-actions-show="false"
      :show-toolbar="false">
    <template v-slot:content>
      <div style="width:100%;position: relative;" :style="{height: screenHeight + 'px'}">
        <v-carousel
            ref="carousel"
            v-model="carouselItem"
            :show-arrows="false"
            :height="screenHeight"
            :continuous="false"
            hide-delimiters
            class="carousel-box"
            :eager="true"
            @change="carouselChange">
          <v-carousel-item
              class="slider-flex"
              v-for="(item, i) in someList"
              :style="item.style "
              :key="i">
            <div :class="item.infoBox.direction + '-box'">
              <div class="content-box"
                   :style="item.contentBox.style">
                <div
                    :style="item.img.style">
                  <LivePhotos
                      v-if="item.img.info.livePhotosId"
                      :live-photo-style="item.img.style"
                      :video-src="item.img.livePhotoUrl"
                      :photo-src="item.img.url"></LivePhotos>
                  <v-img
                      v-else
                      :src="item.img.url"/>
                </div>
              </div>
              <div :class="'animate__animated animate__fadeIn info-box-' + item.infoBox.direction" v-if="overlayVisible"
                   :style="item.infoBox.style">
                <!--                <div class="info-box-line picture-param"-->
                <!--                     v-text="item.img.info.dateTimeOriginal"/>-->
                <div :class="'info-box-flex-wrap' + ($vuetify.breakpoint.xsOnly ? '-xs' : '')">
                  <div class="exclusive-line info-box-line text-body-1 text-lg-h6 font-weight-medium"
                       v-if="item.img.info.make">
                    <div v-text="item.img.info.make"></div>
                    <div style="margin-left: 8px;" v-text="item.img.info.model"></div>
                  </div>
                  <div class="info-box-top-line text-body-2 text-lg-h6 font-weight-medium"
                       v-if="item.img.info.make !== 'Apple'">
                    <div v-text="item.img.info.lensMake + ' ' + item.img.info.lensInfo"></div>
                  </div>
                  <div class="text-body-2 info-box-bottom-line" v-if="item.img.info.exposureTime">
                    <div class="left" v-text="focalLengthFormat(item)"></div>
                    <div class="center" v-text="item.img.info.exposureTime +' s'"></div>
                    <div class="center" v-text="fnNumberFormat(item)"></div>
                    <div class="right" v-text="'ISO '+item.img.info.ISO"></div>
                  </div>
                  <div class="text-body-2 info-box-bottom-line">
                    <div class="left" v-text="item.img.info.dateTimeOriginal"></div>
                  </div>
                </div>
                <div :class="'info-box-flex-wrap' + ($vuetify.breakpoint.xsOnly ? '-xs' : '') + 'fujifilm-param'"
                     v-if="item.img.info.make === 'FUJIFILM'">
                  <div class="info-box-line text-lg-h5 font-weight-medium  exclusive-line">
                    <div v-text="'胶片模拟:'"></div>
                    <div v-text="item.img.info.filmModeFormat"></div>
                  </div>
                  <div class="text-body-2 info-box-bottom-line">
                    <div class="left" v-text="'降噪:' + item.img.info.noiseReductionFormat"/>
                    <div class="center" v-text="'阴影:' + item.img.info.shadowToneFormat"/>
                    <div class="right" v-text="'高光: ' +item.img.info.highlightToneFormat"/>
                  </div>
                  <div class="text-body-2 info-box-bottom-line">
                    <div class="left" v-text="'锐度:' +item.img.info.sharpnessFormat"/>
                    <div class="center" v-text="'颗粒效果:' + item.img.info.grainEffectRoughnessFormat"/>
                    <div class="right" v-text="'饱和度:' + item.img.info.saturationFormat"/>
                  </div>
                  <div class="text-body-2 info-box-bottom-line">
                    <div class="left" v-text="'色彩效果:' + item.img.info.colorChromeEffectFormat"/>
                    <div class="center" v-text="'动态范围:' + item.img.info.dynamicRangeFormat"></div>
                    <div class="right" v-text="''"/>
                  </div>
                </div>
              </div>
            </div>
          </v-carousel-item>
        </v-carousel>
        <v-card-actions
            ref="actionGroup"
            transition="scroll-y-transition"
            class="action-group"
            v-show="actionGroup.show"
            :style="actionGroup.style">
          <div class="action-progress-bar" v-if="someList.length > 1">
            <div class="progress-bar-box" :key="index" v-for="(item,index) in someList">
              <div :class="'progress-bar' + (item.curr ? '-curr':'')"></div>
            </div>
          </div>
          <div v-else class="action-progress-bar-empty">

          </div>
          <div class="action-buttons">
            <v-btn
                icon
                small
                @click="closeDialog">
              <v-icon class="actions-icon">mdi-close</v-icon>
            </v-btn>
            <!--            <v-btn-->
            <!--                icon-->
            <!--                small-->
            <!--                @click="download">-->
            <!--              <v-icon class="actions-icon">mdi-download</v-icon>-->
            <!--            </v-btn>-->
            <v-btn
                icon
                small
                @click="download"
                v-if="showTextBtn"
            >
              <v-icon class="actions-icon">mdi-text-long</v-icon>
            </v-btn>
            <v-btn
                icon
                small
                @click="openImgInfo">
              <v-icon :class="'actions-icon' +( overlayVisible ?'-high':'')">mdi-information-outline
              </v-icon>
            </v-btn>
          </div>

        </v-card-actions>
      </div>
    </template>

  </customize-dialog>
</template>
<script>
import CustomizeDialog from "@/components/CustomizeDialog/index.vue";
import {getPublicContent} from "@/api/admin/content";
import {getPublicResourceDescList} from "@/api/admin/resourceDesc";
import common from "@/util/common";
import LivePhotos from "@/components/LivePhotos/index.vue";

export default {
  name: "FrontContentIndex",
  components: {
    LivePhotos,
    CustomizeDialog,
  },
  props: {
    contentDialogShow: {
      type: Boolean,
      default: false
    },
    contentId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      copyContentDialogShow: true,
      loading: false,
      copyContentId: '',
      someList: [],
      options: {
        currentPage: 0,
      },
      title: '',
      text: '',
      content: {},
      resources: [],
      isStart: true,
      screenHeight: 0,
      screenWidth: 0,
      contentHeight: 0,
      contentWidth: 0,
      boxResizeFun: undefined,
      viewSlider: true,
      currentPage: 0,
      firstImgMaxMarginLeft: 0,
      bottomEmptyArea: 58,
      actionHeight: 48,
      actionGroup: {
        show: true,
        raw: {
          top: 0,
          left: 0
        },
        style: {
          top: '',
          left: ''
        }
      },
      carouselItem: 0,
      overlayVisible: false,
      boxDirection: false,
      imgAllInfo: {
        img: {
          url: '',
          rawData: {
            imageWidth: 0,
            imageHeight: 0,
          },
          style: {
            width: '0px',
            height: '0px',
          },
        },
        screen: {
          width: 0,
          height: 0,
        },
        contentBox: {
          width: 0,
          height: 0,
        },
        infoBox: {
          width: 0,
          height: 0,
        },
      },
      showTextBtn: false
    };
  },
  computed: {},
  watch: {
    contentDialogShow(val) {
      this.copyContentDialogShow = val;
      this.carouselItem = 0;
      this.overlayVisible = false;
      this.initPage();
    },
    contentId(val) {
      this.copyContentId = val;
      this.initContent();
    },
  },
  async mounted() {
    this.copyContentDialogShow = this.contentDialogShow;
    await this.actionGroupResize()();
    this.initPage();
    await this.initContent();
    this.handleMousemove();
    this.boxResize = common.debounce(this.boxResize(), 100);
    this.actionGroupResize = common.debounce(this.actionGroupResize(), 100);
    this.reloadImg = common.debounce(this.reloadImg(), 100);
    window.onresize = () => {
      this.$nextTick(() => this.reloadImg());
    };
  },
  methods: {
    initPage() {
      this.$vuetify.breakpoint.update()
      this.screenWidth = this.$vuetify.breakpoint.width;
      this.screenHeight = this.$vuetify.breakpoint.height;
      this.contentWidth = this.screenWidth - this.getWidthAnEmptyArea();
      this.contentHeight = this.screenHeight - (this.screenHeight - this.actionGroup.raw.top)
      this.copyContentId = this.contentId;
      this.boxDirection = this.screenHeight > this.screenWidth ? 'portrait' : 'horizontal';
    },
    focalLengthFormat(item) {
      if (item.img.info.focalLength) {
        return item.img.info.focalLength.replaceAll("mm", " mm")
      }
      // if (item.img.info.focalLengthIn35mmFormat) {
      //   return '(35mm等效焦距:' + item.img.info.focalLengthIn35mmFormat + ")"
      // }
      return ''
    },
    fnNumberFormat(item) {
      return "f/" + item.img.info.FNumber
    },
    async openImgInfo() {
      this.overlayVisible = !this.overlayVisible
      const itemArray = this.someList;
      for (let i = 0; i < itemArray.length; i++) {
        const item = itemArray[i];
        await this.loadImg(item);
      }
      this.someList = itemArray
    },
    async loadImg(item) {
      if (this.screenHeight > this.screenWidth) {
        item.screen = {
          width: this.$vuetify.breakpoint.width,
          height: this.getItemScreenHeight(item),
        };
      } else {
        item.screen = {
          width: this.getItemScreenWidth(item),
          height: this.$vuetify.breakpoint.height,
        };
      }
      item.contentBox = {
        style: {},
        rawData: {
          height: item.screen.height - (this.screenHeight - this.actionGroup.raw.top),
          width: item.screen.width,
        }
      };
      item.infoBox = {
        rawData: {
          width: this.getBoxInfoWidth(item),
          height: item.contentBox.rawData.height,
        },
        style: {},
        direction: this.getBoxInfoDirection(item),
      };
      let img = this.drawBoxByX(item);
      if (img.height > item.contentBox.rawData.height) {
        img = this.drawBoxByY(item)
      }
      let margin = this.loadMargin(item.img, item.contentBox);
      item.infoBox.style.height = item.infoBox.rawData.height + 'px';
      item.infoBox.style.width = item.infoBox.rawData.width + 'px';
      if (margin.marginLeft > 0
          && !this.$vuetify.breakpoint.xsOnly
          && !this.$vuetify.breakpoint.smOnly) {
        item.infoBox.style.paddingRight = margin.marginLeft + 'px';
      }
      if (margin.marginLeft > 0 && this.screenWidth === item.infoBox.rawData.width) {
        item.infoBox.style.paddingRight = this.getWidthAnEmptyArea() + 'px';
        item.infoBox.style.paddingLeft = this.getWidthAnEmptyArea() + 'px';
      }
      item.img.rawData.width = img.width;
      item.img.rawData.height = img.height;
      item.img.style.width = item.img.rawData.width + 'px';
      item.img.style.height = item.img.rawData.height + 'px';
      item.contentBox.style.width = item.contentBox.rawData.width + 'px';
      item.contentBox.style.height = item.contentBox.rawData.height + 'px';
      item.contentBox.style.display = 'flex';
      item.contentBox.style.flexWrap = 'wrap';
      if (this.overlayVisible) {
        if (item.img.rawData.width > item.img.rawData.height || this.screenWidth < this.screenHeight) {
          item.contentBox.style.alignContent = 'center';
          item.contentBox.style.justifyContent = 'center';
        } else {
          item.contentBox.style.alignContent = 'center';
          item.contentBox.style.justifyContent = 'right';
          item.contentBox.style.paddingRight = (this.getWidthAnEmptyArea() / 2) + 'px';
        }
      } else {
        item.contentBox.style.alignContent = 'center';
        item.contentBox.style.justifyContent = 'center';
      }
    },
    loadMargin(img, content) {
      let emptyX = this.getWidthAnEmptyArea();
      let marginLeft = Math.ceil(emptyX / 2);
      if (marginLeft < 0) {
        marginLeft = 0;
      }
      let marginTop = 0;
      if (content.rawData.height > img.height) {
        marginTop = Math.ceil((content.rawData.height - img.height) / 2)
      }
      return {
        marginLeft, marginTop
      };
    },
    getItemScreenWidth() {
      if (this.overlayVisible) {
        return Math.round(this.$vuetify.breakpoint.width * 0.6)
      } else {
        return this.$vuetify.breakpoint.width;
      }
    },
    getItemScreenHeight() {
      if (this.overlayVisible) {
        return Math.round(this.$vuetify.breakpoint.height * 0.7)
      } else {
        return this.$vuetify.breakpoint.height;
      }
    },
    getBoxInfoWidth(item) {
      if (this.screenWidth > this.screenHeight) {
        return this.screenWidth - item.screen.width;
      } else {
        return this.screenWidth
      }
    },
    getBoxInfoDirection() {
      if (this.screenHeight > this.screenWidth) {
        return 'portrait';
      }
      return 'horizontal';
    },
    drawBoxByY(item) {
      const height = item.contentBox.rawData.height;
      const scale = height / item.img.rawData.imageHeight;
      const width = item.img.rawData.imageWidth * scale;
      return {width, height}
    },
    drawBoxByX(item) {
      const width = item.contentBox.rawData.width - this.getWidthAnEmptyArea();
      const scale = width / item.img.rawData.imageWidth;
      const height = item.img.rawData.imageHeight * scale;
      return {width, height}
    },
    carouselChange(currIndex) {
      this.someList.forEach((item, index) => {
        item.curr = (index === currIndex) || false;
      })
      const next = (currIndex + 1);
      if (next < this.someList.length) {
        new Image().src = this.someList[next].img.url;
      }
    },
    reloadImg() {
      return async () => {
        this.$vuetify.breakpoint.update()
        await this.actionGroupResize();
        this.screenWidth = this.$vuetify.breakpoint.width;
        this.screenHeight = this.$vuetify.breakpoint.height;
        this.contentWidth = this.screenWidth - this.getWidthAnEmptyArea();
        this.contentHeight = this.screenHeight - (this.screenHeight - this.actionGroup.raw.top)
        this.boxDirection = this.screenHeight > this.screenWidth ? 'portrait' : 'horizontal';
        await this.boxResize();
        this.$forceUpdate();
      }

    },
    handleMousemove() {
      let mousemoveStart = false;
      // 初始位置
      let startX = 0;
      // 当前位置
      let endX = 0;
      let startTime = 0;
      document.addEventListener("mousemove", (event) => {
        // 检测鼠标左键是否被按下
        if (event.buttons === 1) {
          if (!mousemoveStart) {
            startX = event.clientX;
            startTime = Date.now();
          }
          mousemoveStart = true;
        } else if (mousemoveStart) {
          endX = event.clientX
          const endTime = Date.now();
          const executionTime = endTime - startTime;
          if (executionTime > 160) {
            mousemoveStart = false;
            return;
          }
          mousemoveStart = false;
          if (endX > startX) {
            if ((endX - 10) > startX) {
              this.carouselItem = (this.carouselItem - 1) < 0 ? this.carouselItem : this.carouselItem - 1;
            }
          } else {
            if ((startX - 10) > endX) {
              this.carouselItem = (this.carouselItem + 1) > (this.someList.length - 1) ? this.carouselItem : this.carouselItem + 1;
            }
          }
          this.someList.forEach((item, index) => {
            item.curr = (index === this.carouselItem) || false;
            this.showTextBtn = (item.curr && item.text);
          })
        }
      });
    },
    actionGroupResize() {
      return async () => {
        this.actionGroup.show = false;
        let actionGroupWidth = 538
        if (this.$vuetify.breakpoint.width < actionGroupWidth) {
          actionGroupWidth = this.$vuetify.breakpoint.width - 10;
        }
        const emptyArea = this.$vuetify.breakpoint.width - actionGroupWidth;
        const left = Math.ceil(emptyArea / 2);
        // action group 距离屏幕顶部的距离
        let actionGroupTop = this.$vuetify.breakpoint.height - this.bottomEmptyArea + ((this.bottomEmptyArea - this.actionHeight) / 2);
        // 减去 12 个像素，避开操作系统的滑动条
        actionGroupTop -= 12;
        this.actionGroup.raw = {
          top: actionGroupTop,
          left: left,
          width: actionGroupWidth,
        };
        this.actionGroup.style = {
          top: this.actionGroup.raw.top + 'px',
          left: this.actionGroup.raw.left + 'px',
          width: this.actionGroup.raw.width + 'px',
        }
        setTimeout(async () => {
          this.actionGroup.show = true;
        }, 20)
      }
    },
    getHeightAnEmptyArea() {
      return this.bottomEmptyArea * 2
    },
    getWidthAnEmptyArea() {
      if (this.$vuetify.breakpoint.xs) {
        return 0;
      }
      if (this.$vuetify.breakpoint.sm) {
        if (this.screenWidth < this.screenHeight) {
          return 0
        }
      }
      return (this.bottomEmptyArea * 2);
    },
    download() {

    },
    boxResize() {
      return async () => {
        const itemArray = this.someList;
        for (let i = 0; i < itemArray.length; i++) {
          const item = itemArray[i];
          await this.loadImg(item);
        }
        this.someList = JSON.parse(JSON.stringify(itemArray));
      }
    },
    async initContent() {
      if (!this.copyContentId) {
        return;
      }
      this.loading = true;
      this.overlayVisible = false;
      this.someList = [];
      this.carouselItem = 0;
      const contentRes = await getPublicContent({id: this.copyContentId})
      this.loading = false;
      if (contentRes.code !== 0) {
        this.loading = false;
        this.$message.closeButtonContent('close').error(contentRes.msg);
        return;
      }
      this.content = contentRes.data
      const getResourceDescListRes = await getPublicResourceDescList({contentId: this.copyContentId});
      this.resources = getResourceDescListRes.data
      if (!this.resources || this.resources.length === 0) {
        return
      }
      const temp = [];
      this.resources.forEach((item, index) => {
        const tempItem = JSON.parse(JSON.stringify(this.imgAllInfo))
        const {fileId, imageWidth, imageHeight} = item;
        tempItem.curr = index === 0
        tempItem.img = {
          url: common.staticSource + '/public/img/'
              + this.settings.getSettingsJson().resourceCompressionRatio
              + '-'
              + fileId,
          info: {
            ...item,
          },
          rawData: {
            imageWidth: imageWidth,
            imageHeight: imageHeight,
          },
          style: {}
        }
        if (item.livePhotosId) {
          tempItem.img.livePhotoUrl = common.staticSource + '/public/video/70-' + item.livePhotosId;
        }
        this.loadImg(tempItem);
        temp.push(tempItem);
      })
      this.someList = temp;
    },
    closeDialog() {
      this.$emit('closeContentDialog');
    },
  },
}
</script>
<style scoped>

/deep/ .slider-pagination {
  display: none;
}

/deep/ .slider-item-flux--center {
  justify-content: center;
}

/deep/ .slider-item-no-flux--center {
//transform: translate3d(12px, 0px, 0px); justify-content: unset;
}

.img-div-no--flex {
  margin-left: 10px;
}

.img-div--flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.action-group {
  position: absolute;
  top: -100px;
  left: -100px;
  height: 48px;
  background: #19191c;
  z-index: 2001;
  display: block;
  border-radius: 10px;
  padding: 0 12px;

  /deep/ .v-btn--icon.v-size--default {
    height: 100%;
  }

}

.action-progress-bar {
  height: 18px;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
}

.action-progress-bar-empty {
  height: 11px;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
}

.action-buttons {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
}

/deep/ .actions-icon {
  color: #959595 !important;
}

/deep/ .actions-icon-high {
  color: #FFFFFF !important;
}

.carousel-box {
  background: linear-gradient(to top, rgb(35, 37, 38), rgb(65, 67, 69));
}

.img-div-top {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: center;
  align-items: flex-start;
}

.img-div-center {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: flex-start;
}


.progress-bar-box {
  flex: 1;
  padding-left: 1px;
  padding-right: 1px;
  height: 2px;
}


.progress-bar {
  height: 2px;
  background: #959595;
}

.progress-bar-curr {
  height: 2px;
  background: #FFFFFF;
}

.info-box-horizontal {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  flex-direction: column;
  justify-content: center;
  color: #fff;
}

.info-box-portrait {
  display: flex;
  flex-wrap: wrap;
  align-content: space-around;
  flex-direction: column;
  justify-content: flex-start;
  color: #fff;
  padding: 0 6px;
}

.info-box-line {
  padding: 2px 6px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0 2px;
}

.info-box-bottom-line {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  padding: 2px 6px;
  border-top: 2px solid black;

  div {
    flex: 1;
  }

  .left {
    text-align: left
  }

  .center {
    text-align: center;
  }

  .right {
    text-align: right;
  }
}

.info-box-top-line {
  padding: 2px 6px;
}

.portrait-box {
  display: flex;
  flex-wrap: wrap;
}

.horizontal-box {
  display: flex;
}

.info-box-flex-wrap-xs {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  border-radius: 5px;
}

.fujifilm-param {
  border-left: 2px solid rgb(25, 25, 29);
  border-right: 2px solid rgb(25, 25, 29);
  border-bottom: 2px solid rgb(25, 25, 29);
}

.info-box-flex-wrap {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 0 2px;
}

.exclusive-line {
  background: black;
  flex-basis: 100%
}
</style>
