export function registerStorageListener(vueInstance) {
    console.log('vueInstance', vueInstance)
//     let oldValue =null;
//     function handleStorageChange(event) {
//         if (event.storageArea === localStorage) {
//             let newValue = null;
//             if (event.newValue) {
//                 newValue = JSON.parse(event.newValue);
//             }
//             const currDark = newValue['dark'];
//             if (currDark != null && currDark !== oldValue) {
//                 settings.changeDark();
//             }
//             oldValue = currDark;
//         }
//     }
//
//     // 监听 localStorage 变化
//     window.addEventListener('storage', handleStorageChange);
//
//     // 实例销毁前移除事件监听器
//     vueInstance.$on('hook:beforeDestroy', () => {
//         window.removeEventListener('storage', handleStorageChange);
//     });
}
