<template>
  <v-container style="height: 100vh" class="container-login">
    <v-row align="center" justify="center">
      <v-col cols="12" lg="6">
        <v-card
            elevation="2"
        >
          <v-form v-model="valid" ref="form">
            <v-container>
              <v-row>
                <v-col
                    cols="12"
                    lg="12"
                    md="4"
                >
                  <v-text-field
                      v-model="form.username"
                      :rules="usernameRules"
                      :counter="10"
                      label="Username"
                      required
                  ></v-text-field>
                </v-col>

                <v-col
                    cols="12"
                    lg="12"
                    md="4"
                >
                  <v-text-field
                      v-model="form.password"
                      :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                      :rules="passwordRules"
                      :type="show ? 'text' : 'password'"
                      name="input-10-2"
                      label="Password"
                      hint="请输入密码"
                      @click:append="show = !show"
                  ></v-text-field>
                </v-col>

                <!--                <v-col-->
                <!--                    cols="12"-->
                <!--                    lg="12"-->
                <!--                    md="4"-->
                <!--                >-->
                <!--                  <v-text-field-->
                <!--                      v-model="email"-->
                <!--                      :rules="emailRules"-->
                <!--                      label="E-mail"-->
                <!--                      required-->
                <!--                  ></v-text-field>-->
                <!--                </v-col>-->
              </v-row>
            </v-container>
          </v-form>
          <v-card-actions>
            <!--            <v-btn text>-->
            <!--              Cancel-->
            <!--            </v-btn>-->
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                text
                @click="submit"
            >
              Submit
            </v-btn>
          </v-card-actions>
        </v-card>

      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import {login} from "@/api/admin/account";

import {setToken} from "@/util/token";

export default {
  name: "AdminLogin",
  data() {
    return {
      valid: false,
      form: {
        username: '',
        password: '',

      },
      usernameRules: [
        v => !!v || 'Username is required',
        v => (v.length >= 3 && v.length <= 20) || 'Username must be between 3 and 20 characters',
        v => /^[a-zA-Z0-9_-]+$/.test(v) || 'Username can only contain letters, numbers, underscores, and hyphens'
      ],
      show: false,
      password: 'Password',
      passwordRules: [
        v => !!v || 'Password is required',
        v => v.length > 8 || '密码长度大于8个字符',
        v => {
          const hasUpperCase = /[A-Z]/.test(v);
          const hasLowerCase = /[a-z]/.test(v);
          const hasNumbers = /[0-9]/.test(v);
          const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(v);
          const validConditions = [hasUpperCase, hasLowerCase, hasNumbers, hasSpecialChar].filter(Boolean).length;
          return validConditions >= 3 || '密码长度大于8个字符，由大写字母、小写字母、数字、符号中的3种及3种以上组成';
        }
      ],
      redirect: undefined
    }
  },
  watch: {
    $route: {
      handler: function (route) {
        this.redirect = route.query && route.query.redirect
      },
      immediate: true
    }
  },
  methods: {
    async submit() {
      if (!this.$refs.form.validate()) {
        return
      }
      const res = await login(this.form);
      if (res.code === 0) {
        setToken(res.data.token)
        await this.$router.push({path: this.redirect || '/admin'})
      } else {
        this.$message.closeButtonContent('close').error(res.msg);
      }
    },
  }
}
</script>
<style scoped>
.container-login {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
}
</style>
