<template>
  <v-dialog
      v-model="dialog"
      :persistent="true"
      :no-click-animation="true"
      transition="dialog-bottom-transition"
      :dark="$vuetify.theme.dark"
      :max-width="400"
  >
    <v-card>
      <v-toolbar
          :dense="true"
          color="primary"
          :dark="true"
      >{{ title || '提示' }}
      </v-toolbar>
      <v-card-text style="padding: 12px" class="confirm-card-text">
        <span
            :class="$vuetify.theme.dark ? 'dark-text ' + $vuetify.breakpoint.name : 'light-text ' + $vuetify.breakpoint.name"
            v-text="message"/>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn
            text
            @click.stop="cancel"
        >关闭
        </v-btn>
        <v-btn
            text
            @click="ok"
        >确定
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script>
export default {
  name: "ConfirmIndex",
  data() {
    return {
      promiseStatus: null,
      dialog: false,
      title: '',
      message: '',
    }
  },
  mounted() {

  },
  methods: {
    ok() {
      this.dialog = false;
      this.$emit('ok');
    },
    cancel() {
      this.dialog = false;
      this.$emit('cancel');
    }
  }
}
</script>

<style scoped>

</style>
