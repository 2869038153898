let _this = null;
let colorSchemeAuto = {
    no: '0',
    yes: '1',
}
let isDarkCode = {
    no: '0',
    yes: '1',
}
let settingsJson = {};

const fun = {
    setThis(That) {
        _this = That;
    },
    getSettingsJson() {
        return settingsJson;
    },
    getSettings() {
        return JSON.parse(localStorage.getItem('settings'));
    },
    loadLocalStorage() {
        const settings = this.getSettings();
        if (settings.dark && settings.dark !== isDarkCode.no) {
            _this.$vuetify.theme.dark = true;
            document.querySelector('body').setAttribute('style', 'background-color:#353739');
        } else {
            _this.$vuetify.theme.dark = false;
            settings.dark = isDarkCode.no;
            document.querySelector('body').setAttribute('style', 'background-color:#FFFFFF');
        }
        // 网页颜色是否跟随系统颜色改变
        if (!settings.colorSchemeBySystem) {
            settings.colorSchemeBySystem = colorSchemeAuto.yes
        }
        if (!settings.resourceCompressionRatio) {
            settings.resourceCompressionRatio = '50';
        }
        // livePhotos
        if (!settings.livePhotos) {
            settings.livePhotos = {autoLoad: '1'}
        }
        this.setSettings(settings);
    },
    openColorSchemeBySystem() {
        const settings = this.getSettings();
        if (settings.colorSchemeBySystem === colorSchemeAuto.yes) {
            return;
        }
        settings.colorSchemeBySystem = colorSchemeAuto.yes;
        const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
        if (darkModeMediaQuery.matches) {
            _this.$vuetify.theme.dark = true;
            settings.dark = isDarkCode.yes;
        } else {
            _this.$vuetify.theme.dark = false;
            settings.dark = isDarkCode.no;
        }
        this.setSettings(settings);
    },
    changeColorAndColorSchemeBySystem(color) {
        const settings = this.getSettings();
        settings.colorSchemeBySystem = colorSchemeAuto.no;
        settings.dark = color;
        console.log('isDarkCode.yes === settings.dark', isDarkCode.yes === settings.dark)
        if (isDarkCode.yes === settings.dark) {
            document.querySelector('body').setAttribute('style', 'background-color:#353739');
            _this.$vuetify.theme.dark = true;
        } else {
            document.querySelector('body').setAttribute('style', 'background-color:#FFFFFF');
            _this.$vuetify.theme.dark = false;
        }
        this.setSettings(settings);
    },
    changeDark() {
        _this.$vuetify.theme.dark = !_this.$vuetify.theme.dark
        const settings = this.getSettings();
        settings.dark = _this.$vuetify.theme.dark ? isDarkCode.yes : isDarkCode.no;
        this.setSettings(settings);
        console.log('123')
    },
    openDark(_this) {
        _this.$vuetify.theme.dark = true;
        const settings = this.getSettings();
        settings.dark = isDarkCode.yes;
        this.setSettings(settings);

    },
    closeDark(_this) {
        _this.$vuetify.theme.dark = false;
        const settings = this.getSettings();
        settings.dark = isDarkCode.no;
        settingsJson = settings;
        this.setSettings(settings);
    },
    setResourceCompressionRatio(compressionRatio) {
        if (compressionRatio !== '100' && compressionRatio !== '70' && compressionRatio !== '50') {
            return
        }
        const settings = this.getSettings();
        settings.resourceCompressionRatio = compressionRatio;
        this.setSettings(settings);
    },
    changeLivePhotosAutoLoad(isAutoLoad) {
        if (isAutoLoad !== '1' && isAutoLoad !== '0') {
            return;
        }
        const settings = this.getSettings();
        settings.livePhotos.autoLoad = isAutoLoad;
        this.setSettings(settings);
    },
    setSettings(settings) {
        settingsJson = settings;
        localStorage.setItem('settings', JSON.stringify(settings));
    },
}

export default fun;

export function registerColorScheme(_this) {
    if (!localStorage.getItem('settings')) {
        localStorage.setItem('settings', '{}');
    }
    fun.setThis(_this);
    fun.loadLocalStorage();
    const darkModeQuery = window.matchMedia('(prefers-color-scheme: dark)');
    handleDarkModeChange(darkModeQuery);
    if (darkModeQuery.addEventListener) {
        darkModeQuery.addEventListener('change', handleDarkModeChange);
    } else {
        darkModeQuery.addListener(handleDarkModeChange);
    }
}

export function removeColorScheme() {
    const darkModeQuery = window.matchMedia('(prefers-color-scheme: dark)');
    darkModeQuery.removeEventListener('change', handleDarkModeChange);
}

function handleDarkModeChange(e) {
    const settings = JSON.parse(localStorage.getItem('settings'));
    if (settings.colorSchemeBySystem === colorSchemeAuto.yes) {
        if (e.matches) {
            fun.openDark(_this);
        } else {
            fun.closeDark(_this);
        }

    }
}