import Router from "vue-router";
import HomeIndex from "@/views/home/index.vue";
import AdminIndex from "@/views/admin/index.vue";
import ContentManagement from "@/views/admin/contentManagement/index.vue"
import AdminHome from "@/views/admin/home/index.vue"
import login from "@/views/login.vue"
import UnauthorizedIndex from "@/views/error/401.vue"
import FrontPage from "@/views/home/frontpage/index.vue"

export const adminRouter = [

    {
        path: 'content/management',
        component: ContentManagement,
        meta: {
            id: 2,
            title: '内容管理',
            parentRoute: '/admin',
            icon: '',
            sort: 1
        }
    },
    {
        path: '',
        component: AdminHome,
        meta: {
            id: 1,
            title: '首页',
            parentRoute: '/admin',
            icon: '',
            sort: 0
        }

    }
];
const homeRouter = [
    {
        path: '/login',
        name: 'LoginIndex',
        component: login,
        meta: {
            keepAlive: false
        }
    },
    {
        path: '',
        name: 'FrontPage',
        component: FrontPage,
        meta: {
            keepAlive: true
        }
    },
    {
        path: '/401',
        name: 'UnauthorizedIndex',
        component: UnauthorizedIndex,
        meta: {
            keepAlive: true
        }
    },

]
const routes = [

    {
        path: "/",
        name: "HomeIndex",
        component: HomeIndex,
        children: homeRouter,
        meta: {
            keepAlive: false
        }
    },
    {
        path: '/admin',
        name: 'AdminIndex',
        component: AdminIndex,
        children: adminRouter,
        meta: {
            keepAlive: true
        }
    },

];

export default new Router({
    mode: 'history',
    routes
});

