<template>
  <v-dialog
      v-model="copyShowDialog"
      v-bind="$attrs"
      v-on="$listeners"
      :width="$vuetify.breakpoint.xsOnly ? '100%' : copyWidth"
      :persistent="copyPersistent"
      :no-click-animation="true"
      :overlay-opacity="0.7"
      :overlay-color="'#7F7F7F'"
      :fullscreen="copyWidth ? false : copyFullscreen">
    <CustomizeLoading
        :style="{
        width: $vuetify.breakpoint.xsOnly ? '100%' : copyWidth,
        height: this.customizeCardStyle.height + 'px' }"
        :show="copyLoading"></CustomizeLoading>
    <CustomizeCard
        :style="{height: customizeCardStyle.height +'px'}"
        style="width: 100%"
        :fill-height="true"
        ref="contentCard">
      <v-toolbar
          :style="{height: toolbar.height + 'px', backgroundColor:toolbarColor}"
          v-if="copyShowToolbar"
          ref="dialogToolbar"
          dark
          dense>
        <slot name="toolbarLeftButtons"></slot>
        <v-spacer></v-spacer>
        <slot name="toolbarRightButtons"></slot>
      </v-toolbar>
      <div
          :style="{height:contentDiv.height + 'px'}"
          style="overflow: auto">
        <slot name="content"></slot>
      </div>
      <v-card-actions
          v-if="copyCardActionsShow"
          :style="{
              height: buttonCardStyle.height + 'px',
              boxShadow: '0 0px 4px -1px rgba(0,0,0,.2), 0 0px 5px 0 rgba(0,0,0,.14), 0 0px 10px 0 rgba(0,0,0,.12)',
              position: 'absolute',
              width: '100%'
          }">
        <slot v-if="!copyCenterButtons" name="cardActionsLeftButtons"></slot>
        <v-spacer v-if="!copyCenterButtons"></v-spacer>
        <slot v-if="!copyCenterButtons" name="cardActionsRightButtons"></slot>
        <slot v-if="copyCenterButtons" name="cardActionsCenterButtons"></slot>
      </v-card-actions>
    </CustomizeCard>
    <slot name="childDialog"></slot>
  </v-dialog>
</template>

<script>
import CustomizeCard from "@/components/CustomizeCard/index.vue";
import CustomizeLoading from "@/components/CustomizeLoading/index.vue";

export default {
  name: "CustomizeDialog",
  components: {CustomizeLoading, CustomizeCard},
  props: {
    showDialog: {
      type: Boolean,
      default: () => {
        return false;
      }
    },
    persistent: {
      type: Boolean,
      default: () => {
        return true;
      }
    },
    fullscreen: {
      type: Boolean,
      default: () => {
        return false;
      }
    },
    width: {
      type: String,
      default: () => {
        return '';
      }
    },
    showToolbar: {
      type: Boolean,
      default: () => {
        return false;
      }
    },
    cardActionsShow: {
      type: Boolean,
      default: () => {
        return true;
      }
    },
    loading: {
      type: Boolean,
      default: () => {
        return false;
      }
    },
    centerButtons: {
      type: Boolean,
      default: () => {
        return false;
      }
    },
  },
  data() {
    return {
      copyShowDialog: false,
      copyFullscreen: false,
      copyWidth: '',
      copyShowToolbar: false,
      copyCardActionsShow: false,
      copyPersistent: false,
      copyLoading: false,
      copyCenterButtons: false,
      cardHeight: 0,
      customizeCardStyle: {
        height: 0
      },
      toolbar: {
        height: 48,
        color: '#FFFFFF',
      },
      buttonCardStyle: {
        height: 0
      },
      contentDiv: {
        height: 0
      }
    };
  },
  watch: {
    async showDialog(newVal) {
      this.copyShowDialog = newVal;
      await this.$nextTick();
      this.initPage();
    },
    loading(newVal) {
      this.copyLoading = newVal;
    },
    '$vuetify.breakpoint.height'(val) {
      this.customizeCardStyle.height = val;
      this.contentDiv.height = this.customizeCardStyle.height
          - (this.copyShowToolbar ? this.toolbar.height : 0)
          - (this.copyCardActionsShow ? this.buttonCardStyle.height : 0);
    },
  },
  computed: {
    toolbarColor() {
      return this.$vuetify.theme.dark ? '#1F2020' : '#ffffff'
    },
  },
  async mounted() {
    this.$vuetify.breakpoint.update()
    this.copyShowDialog = this.showDialog;
    await this.$nextTick();
    this.initPage();
  },
  methods: {
    initPage() {
      // setTimeout(() => {
      //   this.dark.changeDark();
      // }, 2000);
      if (this.copyShowDialog) {
        this.copyWidth = this.width;
        this.copyFullscreen = this.fullscreen;
        this.copyShowToolbar = this.showToolbar;
        this.copyPersistent = this.persistent;
        this.copyCardActionsShow = this.cardActionsShow;
        this.copyCenterButtons = this.centerButtons;
        this.copyLoading = this.loading;
        if (this.copyCardActionsShow) {
          this.buttonCardStyle.height = 48;
        }
        this.customizeCardStyle.height = this.$vuetify.breakpoint.height;
        this.contentDiv.height = this.customizeCardStyle.height
            - (this.copyShowToolbar ? this.toolbar.height : 0)
            - (this.copyCardActionsShow ? this.buttonCardStyle.height : 0)
      }
    },
  },
  component: {}
}
</script>

<style scoped>
/deep/ .v-dialog {
  margin: 0;
  max-height: 100%;
}

</style>
