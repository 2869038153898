<template>
    <v-container class="fill-height">
        <v-layout
                align-center
                align-content-center>
            <v-flex>
                <v-container>
                    <v-row
                            class="text-center fill-height "
                            justify="center"
                            no-gutters>
                        <v-col
                                xl="12"
                                md="8"
                                lg="6"
                                align-self="stretch">
                            <CustomizeCard
                                    elevation="0"
                                    class="fill-height"
                                    tile>
                                <h1>401 Unauthorized</h1>
                                <v-card-text>
                                    <p>You are not authorized to access this page.</p>
                                    <p>Please log in or contact an administrator.</p>
                                </v-card-text>
                                <v-card-text>
                                    <v-btn
                                            color="primary"
                                            @click="home">Home
                                    </v-btn>
                                </v-card-text>
                            </CustomizeCard>
                        </v-col>
                    </v-row>
                </v-container>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import router from "@/conf/router";
import CustomizeCard from "@/components/CustomizeCard/index.vue";

export default {
    name: 'UnauthorizedIndex',
    components: {CustomizeCard},
    methods: {
        home() {
            router.replace("/login").then((r,r2)=>{
              console.log(r);
              console.log(r2);
            })
        },
    }
}
</script>

<style scoped>
</style>
