<template>
  <div style="width: 100%">
    <div :class="'container-box-'+ $vuetify.breakpoint.name ">
      <div :class="'container-' + $vuetify.breakpoint.name">
        <v-row
            :style="{backgroundColor: $vuetify.theme.dark ? '#353739' : '#FFFFFF',}"
            style="height: 52px"
            no-gutters
            align-content="center"
            justify-lg="center"
            justify-md="center"
            justify="end"
        >
          <v-col
              class="pl-6 pl-lg-0 pl-sm-0 pr-6 pr-lg-0 pr-sm-0"
              align-self="center"
              :lg="colConf.lg"
              :xl="colConf.xl"
              :md="colConf.md"
              :sm="colConf.sm"
              :cols="colConf.xs"
              :offset-sm="0"
              :offset-md="0"
              :offset-xs="0"
              :offset-lg="0"
              :offset-xl="0"
          >
            <div
                class="toolbar"
                style="height: 52px"
                :style="{backgroundColor: $vuetify.theme.dark ? '#353739' : '#FFFFFF',}"
            >
              <div class="hidden-and-down">
                <div :class="'btn-group'">
                  <div v-for="(item,index) in leftBtnGroup"
                       :key="index"
                       style="margin-left: 4px;"
                       :class="((index === leftBtnGroup.length - 1) ? 'btn-mr-0 ': 'btn-mr-1 ')
                           + ' ' + item.className + ($vuetify.theme.dark ? '-dark ' : '')"
                       @click="selectedBtn(index)">
                    <v-skeleton-loader
                        :class="$vuetify.breakpoint.name"
                        type="button@1"
                        v-if="showSkeleton"
                    ></v-skeleton-loader>
                    <a :class="'text-underline-hover'
                    + ($vuetify.theme.dark ? '-dark ' : ' ')
                    + item.className"
                       v-else v-text="item.name"
                       @click="changeTag(item.tag)"></a>
                  </div>
                </div>
              </div>
              <v-spacer></v-spacer>
              <div class="hidden-and-down">
                <div class="btn-group">
                  <div v-for="(item,index) in rightBtnGroup"
                       :key="index"
                  >
                    <v-skeleton-loader
                        :class="$vuetify.breakpoint.name + 'buttons-skeleton'"
                        type="avatar@1"
                        style="margin-left: 4px;"
                        v-if="showSkeleton"
                    ></v-skeleton-loader>
                    <v-btn
                        v-else
                        @click="fn(item.funcName)"
                        icon
                        small>
                      <v-icon class="actions-icon">{{ item.name }}</v-icon>
                    </v-btn>
                  </div>
                </div>
              </div>


            </div>

          </v-col>
        </v-row>
      </div>

    </div>
    <v-divider
        :style="{border: $vuetify.theme.dark ? '1px solid #1F1F25' : '1px solid #373633'}"></v-divider>
  </div>

</template>


<script>

import {colConf} from "@/views/home/js/layout";

export default {
  name: 'HeadIndex',
  components: {},
  data() {
    return {
      colConf: colConf,
      leftBtnGroup: [
        {
          name: "全部",
          tag: '',
          className: '',
        },
        {
          name: "旅游",
          tag: '1',
          className: '',
        },
        {
          name: "小猫",
          tag: '2',
          className: '',
        },
        {
          name: "文档",
          tag: '3',
          className: '',
        }
      ],
      rightBtnGroup: [
        {
          name: "mdi-tune-variant",
          class: '',
          funcName: 'openSettingDrawer'
        },
        // {
        //   name: "mdi-cog-outline",
        //   class: ''
        // },
        // {
        //   name: "mdi-cog-outline",
        //   class: ''
        // }
      ],
      showSkeleton: true,
      selectTag: '',
    }
  },
  created() {

  },
  methods: {
    fn(method) {
      this[method]()
    },
    openSettingDrawer() {
      this.$emit('openSettingDrawer');
    },
    changeShowSkeleton() {
      this.showSkeleton = false;
      if (this.leftBtnGroup.length > 0 && !this.selectTag) {
        this.selectedBtn(0);
      }
    },
    selectedBtn(index) {
      this.leftBtnGroup.forEach((item, arrIndex) => {
        if (index === arrIndex) {
          this.leftBtnGroup[arrIndex].className = 'selected-btn'
        } else {
          this.leftBtnGroup[arrIndex].className = ''
        }
      })
    },
    changeTag(tag) {
      this.selectTag = tag;
      this.$emit('search', tag)
    },
    changeTagByContent(tag) {
      this.changeTag(tag);
      for (let i = 0; i < this.leftBtnGroup.length; i++) {
        if (this.leftBtnGroup[i].tag === tag) {
          this.selectedBtn(i)
          return;
        }
      }
    },
  }
}
</script>

<style scoped>
/deep/ .container-lg {
  width: calc(100vw - 220px) !important;
}

.container-box-lg, .container-box-sm, .container-box-md {
  display: flex;
  justify-content: center;
}

.container-box-sm {
  display: flex;
  justify-content: center
}

.container-sm {
  width: calc(100vw - 180px)
}

.container-md {
  width: calc(100vw - 260px)
}

/deep/ .toolbar {
  font-size: 0.975rem;
  font-weight: 500;
  line-height: 1.75rem;
  padding: 20px 0 4px;
  display: flex;
  flex-direction: row;
}

/deep/ .v-skeleton-loader__button {
  border-radius: 4px;
  height: 18px;
  width: 34px;
}

.btn-group {
  display: flex;
  flex-wrap: nowrap;
}

.actions-icon {
  color: #959595 !important;
}

/deep/ .v-skeleton-loader__avatar {
  width: 24px;
  height: 24px;
}
</style>
