import axios from 'axios'
import {getToken, needToken, removeToken} from "@/util/token";
import router from "@/conf/router";
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'


const instance = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    timeout: 100000,
    headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
})
// 拦截器
instance.interceptors.request.use(config => {
    console.log('config.url', config.url)
    if (needToken(config.url)) {
        config.headers['Authorization'] = 'Bearer ' + getToken()
    }
    // const baseURL = config.baseURL;
    // console.log(config);

    return config;
}, error => {
    console.log(error)
    Promise.reject(error)
})
//
instance.interceptors.response.use(res => {
    // 未设置状态码则默认成功状态
    const code = res.code || 200;
    // // 获取错误信息
    // const message = errorCode[code] || res.msg || errorCode['default'];
    // console.log('message', message)
    // console.log('code', code)
    if (code === 401) {
        location.href = '/401';
    }
    return res.data

}, error => {
    const code = error.response.status
    console.log(error.response.status)
    if (code === 401) {
        router.replace("/401")
            .then(() => {
                removeToken()
            });
    }
    return error.response.data
})

export function buildUpload(url, data, files) {
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
        formData.append('files', files[i]);
    }
    for (let key in data) {
        formData.append(key, data[key]);
    }
    return instance(
        {
            url: url,
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            method: "post",
            data: formData,
        }
    )
}

export default instance
