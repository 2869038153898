<template>
  <div
      :style="{height: containerSize.height + 'px'}"
      style="position: relative;">
    <v-row no-gutters style="width: 100%" v-if="images && images.length > 0">
      <v-col
          :cols="12" v-for="(item,index) in images"
          :key="index">
        <v-card
            draggable="true">
          <div :style="item.divStyle">
            <v-img
                :style="item.imgStyle"
                alt=""
                :src="item.newUrl"/>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <div v-else
         style="position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 50%;
                text-align: center;">
      <v-alert
          border="right"
          color="blue-grey"
          dark
      >
        暂无内容
      </v-alert>
    </div>
  </div>

</template>

<script>
import * as Vibrant from "node-vibrant";

export default {
  name: "CardSorting",
  props: {
    files: {
      type: Array,
      default: () => {
        return []
      }
    },
    containerSize: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      copyFiles: [],
      images: [],
      sourceElement: null,
      targetElement: null,
      buttonArray: [
        {
          icon: 'mdi-arrow-down',
          tooltipText: '',
        }
      ],

    };
  },
  watch: {
    files: {
      handler(newVal) {
        this.copyFiles = newVal;
        this.fileInputChange();
      },
      immediate: true,
      deep: true
    },
  },
  mounted() {
    this.copyFiles = this.files;
    // if (this.copyFiles.length > 0) {
    //   this.fileInputChange();
    // }
  },
  methods: {
    setAsCover(index, cover) {
      this.$emit('changeCover', index, cover);
    },
    async fileInputChange() {
      const getPadding = (breakpoint) => {
        if (breakpoint.lg) {
          return 24;
        }
        if (breakpoint.xl) {
          return 24;
        }
        if (breakpoint.xs) {
          return 12
        }
        return 0
      }
      this.images = [];
      let index = 0;
      for (const item of this.copyFiles) {
        if (item.newUrl) {
          this.images.push(item);
          continue
        }
        const newUrl = window.URL.createObjectURL(this.copyFiles[index]);
        const rgb = await new Vibrant.from(newUrl)
            .quality(1)
            .clearFilters()
            .getPalette();
        const getPopulation = () => {
          return 0;
        }
        let max = {
          getPopulation
        };
        let min = {
          getPopulation
        };
        // 选出最大值与最小值，作为图片背景
        for (let rgbKey in rgb) {
          if (max.getPopulation() < rgb[rgbKey].getPopulation())
            max = rgb[rgbKey];
          if (max.getPopulation() > rgb[rgbKey].getPopulation())
            min = rgb[rgbKey];
        }
        item.divStyle = {
          // 图片占不满容器时，背景显示的颜色
          backgroundImage: 'linear-gradient(to bottom, rgb('
              + max.rgb.toString() + '), rgb('
              + min.rgb.toString() + '))',
          backgroundSize: 'cover',
          padding: getPadding(this.$vuetify.breakpoint) + 'px',
          paddingNumber: getPadding(this.$vuetify.breakpoint)
        }
        item.newUrl = newUrl;
        this.images.push(item);
        index++;
      }
    },
  },
  component: {}
}
</script>

<style scoped>

</style>
