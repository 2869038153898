<template>
  <FormDialog
      ref="formDialog"
      :transition="'dialog-bottom-transition'"
      :show-dialog="copyFormDialogShow"
      :loading="loading"
      :fullscreen="true"
      :width="dialogWidth"
      :show-toolbar="true">
    <template v-slot:toolbarLeftButtons>
      <v-btn
          icon
          dark
          @click="closeDialog">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
    <template v-slot:content>
      <v-row
          style="height: 100%;margin: 0"
          no-gutters>
        <v-col
            :lg="12"
            :xl="12"
            :md="21"
            :cols="12"
            :sm="12"
            :offset-sm="0"
            :offset-md="0"
            :offset-xs="0"
            :offset-lg="0"
            :offset-xl="0"
            ref="contentEditingArea">
          <!--            <v-card-title>编辑</v-card-title>-->
          <v-container>
            <v-row no-gutters ref="radioGroupRow">
              <v-col :cols="12">
                <v-radio-group
                    v-model="type"
                    row>
                  <v-radio
                      v-for="item in contentTypes"
                      :key="item.value"
                      :label="item.type"
                      :value="item.value"
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row no-gutters ref="radioGroupRow">
              <v-col :cols="12">
                <v-radio-group
                    v-model="tag"
                    row>
                  <v-radio
                      v-for="item in contentTags"
                      :key="item.value"
                      :label="item.type"
                      :value="item.value"
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row no-gutters ref="formRow">
              <v-col :cols="12">
                <v-form
                    ref="contentForm"
                    lazy-validation>
                  <v-container>
                    <v-row no-gutters>
                      <v-col :cols="12">
                        <v-text-field
                            v-model="form.title"
                            label="标题"
                            outlined
                            height="20px"
                            counter="20"
                            clearable
                        ></v-text-field>
                      </v-col>
                      <v-col :cols="12">
                        <v-textarea
                            :rows="7"
                            v-model="form.text"
                            label="内容"
                            outlined
                            clearable
                        ></v-textarea>
                      </v-col>
                      <v-col :cols="12">
                        <v-file-input
                            :chips="true"
                            v-model="files"
                            color="deep-purple accent-4"
                            counter
                            label="上传图片"
                            multiple
                            placeholder="选择文件"
                            prepend-icon=""
                            outlined
                            :show-size="1000"
                            @change="fileInputChange"
                            @click:clear="fileClear"
                        >
                          <template v-slot:selection="{ index, text }">
                            <v-chip
                                v-if="index < 4"
                                color="deep-purple accent-4"
                                dark
                                label
                                small
                            >
                              {{ text }}
                            </v-chip>

                            <span
                                v-else-if="index === 4"
                                class="text-overline grey--text text--darken-3 mx-2"
                            >
        +{{ files.length - 4 }} File(s)
      </span>
                          </template>
                        </v-file-input>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-col>
            </v-row>
            <v-row no-gutters ref="tableRow">
              <v-col :cols="12">
                <v-data-table
                    v-if="tableView"
                    :dark="$vuetify.theme.dark"
                    :headers="headers"
                    :items="filesToArray()"
                    :height="$vuetify.breakpoint.xsOnly ? null : tableMaxHeight"
                    :loader-height="2"
                    :disable-pagination="true"
                    :hide-default-footer="true"
                    :disable-sort="true"
                    :disable-filtering="true"
                >
                  <template v-slot:[`item.actions`]="{ item,index }">
                    <v-icon
                        v-if="(index+1) !== files.length"
                        small
                        class="mr-2"
                        @click="sortDown(item,index)"
                    >
                      mdi-arrow-down
                    </v-icon>
                    <v-icon
                        v-if="index > 0"
                        small
                        class="mr-2"
                        @click="sortUp(item,index)"
                    >
                      mdi-arrow-up
                    </v-icon>
                    <v-icon
                        v-if="item.cover === 1"
                        small
                        class="mr-2"
                        @click="setAsCover(item,index)"
                    >
                      mdi-heart
                    </v-icon>
                    <v-icon
                        v-if="item.cover === 2"
                        small
                        class="mr-2"
                        @click="setAsCover(item,index)"
                    >
                      mdi-heart-outline
                    </v-icon>
                    <v-icon
                        small
                        class="mr-2"
                        @click="viewResource(index)"
                    >
                      mdi-image
                    </v-icon>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>

        </v-col>
      </v-row>

    </template>
    <template v-slot:cardActionsRightButtons>
      <v-btn
          color="green darken-1"
          @click="closeDialog"
          text
      >
        关闭
      </v-btn>
      <v-btn
          @click="submit(stateCode.DRAFT)"
          v-if="!currState || currState === stateCode.DRAFT"
          color="green darken-1"
          text
      >
        保存草稿
      </v-btn>
      <v-btn
          @click="submit(stateCode.RELEASE)"
          color="green darken-1"
          text
      >
        {{ submitButton }}
      </v-btn>
    </template>
    <template v-slot:childDialog>
      <ViewResourceDialog
          :transition="'dialog-bottom-transition'"
          :show-dialog="viewResourceDialog"
          :width="dialogWidth"
          :card-actions-show="false"
          :show-toolbar="true">
        <template v-slot:toolbarLeftButtons>
          <v-btn
              icon
              dark
              @click="closeViewResourceDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
        <template v-slot:content>
          <CardSorting
              :files="viewResources"
              :container-size="containerSize"></CardSorting>
        </template>
      </ViewResourceDialog>
    </template>
  </FormDialog>
</template>
<script>
import CardSorting from "@/components/CardSorting/index.vue";
import FormDialog from "@/components/CustomizeDialog/index.vue";
import ViewResourceDialog from "@/components/CustomizeDialog/index.vue";
import {
  addContent,
  addResourceDesc,
  getContent,
  stateCode,
  updateContent
} from "@/api/admin/content";
import {formatFileSize, getFileFromUrl} from "@/util/image";
import {deleteResourceDesc, getResourceDescList} from "@/api/admin/resourceDesc";

export default {
  name: "ContentManagementForm",
  components: {FormDialog, ViewResourceDialog, CardSorting},
  data() {
    return {
      loading: false,
      copyFormDialogShow: false,
      showContentDialog: true,
      viewCardSortingDialog: false,
      viewResourceDialog: false,
      snackbar: true,
      form: {
        title: '',
        text: '',

      },
      dialog: false,
      files: [],
      type: 1,
      tag: 1,
      contentTypes: [
        {
          type: '图片',
          value: 1,
        },
        {
          type: '视频',
          value: 2,
        },
        {
          type: 'Markdown',
          value: 3,
        },
      ],
      contentTags: [
        {
          type: "旅游",
          value: 1,
        },
        {
          type: "小猫",
          value: 2,
        },
        {
          type: "文档",
          value: 3,
        }
      ],
      valid: true,
      containerSize: {
        width: 0,
        height: 0,
      },
      textareaRows: 1,
      currentWindow: 0,
      headers: [
        {text: '名称', value: 'name', align: 'center'},
        {text: '类型', value: 'type', align: 'center'},
        {text: '大小', value: 'mb', align: 'center'},
        {text: '排序', value: 'sort', align: 'center'},
        {text: '操作', value: 'actions', align: 'center'},
      ],
      tableMaxHeight: 0,
      toolBarHeight: 0,
      actions: 0,
      tableView: true,
      currState: '',
      submitButton: '发布',
      stateCode: stateCode,
      viewResources: [],
      tableArrays: [],
      copyContentId: null,
      dialogWidth: '60%',
      deleteResourceIds: [],
      videoType: ["MOV", "MP4", "WEBM"],
      imgType: ["PNG", "JPG", "JPEG", "HEIC"],
      markdownType: ["HTML", "MD", "TEXT/HTML"]
    };
  },
  props: {
    contentId: {
      type: String,
      default: () => {
        return "";
      }
    },
    formDialogShow: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  },
  watch: {
    async formDialogShow(newVal) {
      this.copyFormDialogShow = newVal;
      if (this.copyFormDialogShow) {
        await this.$nextTick();
        this.currState = '';
        this.submitButton = '发布';
        await this.initContent();
        this.initElementStyle();
        await this.$nextTick();
        this.initTableStyle();
      }
    },
    contentId(val) {
      this.copyContentId = val;
    },
  },
  async mounted() {
    this.initDialogWidth();
    this.copyFormDialogShow = this.formDialogShow;
    this.copyContentId = this.contentId;
    if (this.copyFormDialogShow) {
      await this.$nextTick();
      await this.initContent();
      this.initElementStyle();
      await this.$nextTick();
      this.initTableStyle();
    }
  },
  methods: {
    initDialogWidth() {
      if (this.$vuetify.breakpoint.md) {
        this.dialogWidth = '100%';
      }
    },
    viewResource(index) {
      this.viewResources = [this.tableArrays[index].file];
      this.viewResourceDialog = true;
    },
    sortUp(item, index) {
      if (index === 0) {
        return;
      }
      this.swapFiles(index, index - 1);
      this.$forceUpdate();
    },

    sortDown(item, index) {
      if (index === (this.files.length - 1)) {
        return;
      }
      this.swapFiles(index, index + 1);
      this.$forceUpdate();
    },
    reloadTable() {
      this.tableView = false;
      this.tableView = true;
    },
    swapFiles(leftIndex, rightIndex) {
      const oldItem = this.tableArrays[leftIndex];
      this.tableArrays[leftIndex] = this.tableArrays[rightIndex];
      this.tableArrays[rightIndex] = oldItem
    },
    filesToArray() {
      return this.tableArrays.map(item => item.obj)
    },
    setAsCover(item, index) {
      this.changeCover(index, item.cover);
    },
    changeCover(index, cover) {
      for (let i = 0; i < this.tableArrays.length; i++) {
        if (i === index) {
          this.tableArrays[i].obj.cover = (cover === 1 ? 2 : 1)
        } else {
          this.tableArrays[i].obj.cover = 2
        }
      }
      this.reloadTable();
    },
    async initContent() {
      if (!this.copyContentId) {
        this.clearForm();
        return;
      }
      this.loading = true;
      const contentRes = await getContent({id: this.copyContentId})
      this.loading = false;
      if (contentRes.code !== 0) {
        this.loading = false;
        this.$message.closeButtonContent('close').error(contentRes.msg);
        return;
      }
      this.form = contentRes.data
      this.currState = this.form.state;
      this.submitButton = this.changeSubmitButton();
      const getResourceDescListRes = await getResourceDescList({contentId: this.form.id});
      this.urlToFile(getResourceDescListRes)
          .then(files => {
            if (files) {
              files.forEach(item => item.obj.mb = formatFileSize(item.file.size));
              this.tableArrays = files.sort((a, b) => a.obj.sort - b.obj.sort);
              this.files = this.tableArrays.map(item => item.file);
            }
          });
      this.deleteResourceIds = [];
    },
    clearForm() {
      this.form = {
        text: "",
        title: "",
      };
      this.tableArrays = [];
      this.files = [];
    },
    changeSubmitButton() {
      switch (this.currState) {
        case stateCode.RELEASE:
          return '更新';
        case stateCode.DRAFT:
          return '发布';
        case stateCode.HIDE:
          return '更新并取消隐藏';
        default:
          return '发布';
      }

    },
    async urlToFile(res) {
      if (!res.data || res.data.length === 0) {
        this.tableArrays = [];
        this.files = [];
        return
      }
      let tempFiles = [];
      let promises = [];
      for (let i = 0; i < res.data.length; i++) {
        promises.push(this.asyncGetFileFromUrl(res.data[i], tempFiles))
      }
      await Promise.all(promises);
      return tempFiles
    },
    asyncGetFileFromUrl(data, tempFiles) {
      return new Promise(resolve => {
        getFileFromUrl('/public/img/70-' + data.fileId, data.rawFileName, data.type)
            .then(file => {
              tempFiles.push({
                file: file,
                obj: {
                  id: data.id,
                  fileId: data.fileId,
                  type: data.type,
                  name: data.rawFileName,
                  sort: data.sort,
                  cover: data.cover,
                }
              });
              resolve();
            });
      });
    },
    async submit(state) {
      this.form.state = state;
      this.form.type = this.type;
      this.form.tag = this.tag;
      this.loading = true;
      const contentRes = this.form.id ? await updateContent(this.form) : await addContent(this.form);
      if (contentRes.code !== 0) {
        this.loading = false;
        this.$message.closeButtonContent('close').error(contentRes.msg);
        return
      }
      this.copyContentId = contentRes.data.id
      this.currState = this.form.state;
      this.submitButton = this.changeSubmitButton();
      if (!(this.tableArrays && this.tableArrays.length > 0)) {
        this.$emit('closeFormDialog');
        return
      }
      if (this.deleteResourceIds.length > 0) {
        const res = await deleteResourceDesc(this.deleteResourceIds.toString());
        if (res.code !== 0) {
          this.$message.closeButtonContent('close').error(res.msg);
          return;
        }
      }

      const map = new Map();
      this.tableArrays.forEach(item => {
        const name = item.file.name.split(".")[0];
        let array = map.get(name) || [];
        array.push(item)
        map.set(name, array);
      })
      for (let value of map.values()) {
        let data = null;
        for (const item of value) {
          if (item.obj.fileId) {
            continue;
          }
          data = {
            contentId: contentRes.data.id,
            sort: item.obj.sort,
          };
          if (item.file.type.indexOf('image') > -1) {
            data.cover = item.obj.cover;
            break;
          }
        }
        const fileType = this.getFileType(value);
        const res = await addResourceDesc(fileType, data, value.map(item => item.file));
        if (res.code !== 0) {
          this.$message.closeButtonContent('close').error(res.msg);
          this.loading = false;
          return
        }
      }
      await this.initContent();
    },
    getFileType(value) {
      if (value.length === 0) {
        return '';
      }
      if (value.length > 1) {
        return 'livePhotos';
      }
      const file = value[0];
      const types = file.file.name.split(".");
      const type = types[types.length - 1];
      if (this.imgType.indexOf(type.toUpperCase()) > -1) {
        return 'img'
      }
      if (this.videoType.indexOf(type.toUpperCase()) > -1) {
        return 'video'
      }
      if (this.markdownType.indexOf(type.toUpperCase()) > -1) {
        return 'markdown'
      }
    },
    openViewCardSortingDialog() {
      this.viewCardSortingDialog = true;
    },
    closeDialog() {
      this.$emit("closeFormDialog");
    },
    closeViewResourceDialog() {
      this.viewResourceDialog = false;
    },
    async fileInputChange() {
      if (this.tableArrays.length > 0) {
        this.pushDeleteResourceIds();
      }
      this.tableArrays = [];
      for (let i = 1; i <= this.files.length; i++) {
        this.files[i - 1].cover = (i === 1) ? 1 : 2;
        this.files[i - 1].mb = formatFileSize(this.files[i - 1].size);
        this.files[i - 1].sort = i;
        this.tableArrays.push({
          file: this.files[i - 1],
          obj: {
            type: this.files[i - 1].type,
            name: this.files[i - 1].name,
            cover: (i === 1) ? 1 : 2,
            mb: formatFileSize(this.files[i - 1].size),
            sort: i,
          }
        })
      }
    },
    pushDeleteResourceIds() {
      this.tableArrays.forEach(item => {
        if (item.obj && item.obj.id) {
          this.deleteResourceIds.push(item.obj.id);
        }
      })
    },
    fileClear() {
      this.pushDeleteResourceIds();
    },
    initElementStyle() {
      this.toolBarHeight = document.getElementsByClassName('v-toolbar__content')[0].style.height;
      this.actions = document.getElementsByClassName('v-card__actions')[0].style.height
      this.toolBarHeight = parseInt(this.toolBarHeight.replace("px"));
      this.actions = parseInt(this.actions.replace("px"));
      this.containerSize.height = document.body.scrollHeight - (this.toolBarHeight + this.actions)
      this.containerSize.width = this.$refs.contentEditingArea.offsetWidth
    },
    initTableStyle() {
      const radioGroupContainerHeight = this.$refs.radioGroupRow.offsetHeight;
      const formRowHeight = this.$refs.formRow.offsetHeight
      const tableMaxHeight = this.$refs.formDialog.customizeCardStyle.height - (radioGroupContainerHeight + formRowHeight);
      this.tableMaxHeight = tableMaxHeight - this.toolBarHeight - this.actions - 48;
    },
  },
  component: {}
}
</script>

<style scoped>
</style>
